import React from 'react'
import { EloButton } from '@elo-kit/components/elo-button/EloButton'
import { LoadingSpinner } from '@elo-kit/components/loading-spinner/LoadingSpinner'
import { EloModal } from '@elo-kit/components/elo-ui/elo-modal/EloModal'
import { SUPPORT } from 'constants/helpCenter.constants'
import { ACTIVE_PROFILES } from 'constants/profile.constants'
import { SIZES } from '@elo-kit/constants/general.constants'
import { profile } from 'utils/profileHelper.utils'
import { patchLink } from 'utils/link.utils'

interface Props {
  token: string
  isLoading: boolean
}

const TokenIdContent = (props: Props) => {
  const { token, isLoading } = props
  const userType = profile.profileType === ACTIVE_PROFILES.payer ? ACTIVE_PROFILES.payer : ACTIVE_PROFILES.seller
  return (
    <>
      <EloModal.Message>
        <p className='description'>
          {userType === 'payer'
            ? I18n.t('react.shared.tokenId.modal_description_payer')
            : I18n.t('react.shared.tokenId.modal_description_seller')}
        </p>
        {isLoading ? <LoadingSpinner size={SIZES.small} /> : <div className='token-id__text'>{token}</div>}
      </EloModal.Message>
      <div className='content'>
        <div>
          <span>
            {(userType === 'payer'
              ? I18n.t('react.shared.tokenId.submit_request_payer')
              : I18n.t('react.shared.tokenId.submit_request_seller')
            ).toUpperCase()}
          </span>
          <div>
            {userType === 'payer'
              ? I18n.t('react.shared.tokenId.button_label_payer')
              : I18n.t('react.shared.tokenId.button_label_seller')}
          </div>
          <EloButton href={patchLink(SUPPORT.link)} target='_blank' outlined>
            {I18n.t('react.shared.tokenId.submit_button')}
          </EloButton>
        </div>
        <div>
          <span>{I18n.t('react.shared.tokenId.call_number_label')}</span>
          <div>
            {userType === 'payer'
              ? I18n.t('react.shared.tokenId.phone_number_payer')
              : I18n.t('react.shared.tokenId.phone_number_seller')}
          </div>
        </div>
      </div>
    </>
  )
}

TokenIdContent.displayName = 'TokenIdContent'

export default TokenIdContent
