import React, { useState, useEffect } from 'react'
import classNames from 'classnames'
import { computed } from 'mobx'
import { observer } from 'mobx-react'
import camelcase from 'camelcase'

import IconTeam from 'ui/icons/IconTeam.jsx'
import IconPayout from 'ui/icons/IconPayout.jsx'
import { EloCaretDownIcon } from '@elo-ui/components/icons/regular'

import { ACTIVE_PROFILES, UNACTIVE_PROFILES } from 'constants/profile.constants'
import { PUBLISHER_OVERVIEW_PATH } from 'constants/routes/publisher.constants'
import { TEAM_MEMBER_OVERVIEW_PATH } from 'constants/routes/teamMember.constants'
import { COMMUNITY_KEY, MOBILE_APP_OPTION_KEY } from 'constants/options.constants'
import { STATUSES } from 'constants/general.constants'
import { patchLink } from 'utils/link.utils'

import { profile } from 'utils/profileHelper.utils'

import { useSharedStores } from 'shared/hooks/use-shared-stores'

import { CountingItem } from './CountingItem'
import { productsStore } from '../../../admin/stores/products.store'
import { resellerRequestsStore } from '../../../admin/stores/resellerRequests.store'

const ROOT_URLS = [
  '/cabinet',
  '/admin',
  '/terms',
  '/privacy',
  '/elo_publisher',
  '/admin',
  PUBLISHER_OVERVIEW_PATH,
  TEAM_MEMBER_OVERVIEW_PATH,
]

const DynamicLink = ({ differentBundle, LinkComponent, children, to, ...rest }) => (
  <>
    {differentBundle ? (
      <a
        {...{
          ...rest,
          href: to,
        }}
      >
        {children}
      </a>
    ) : (
      <LinkComponent to={to} {...rest}>
        {children}
      </LinkComponent>
    )}
  </>
)

interface MenuItemProps {
  item: {
    href: string
    icon: string
    title: string
    type?: string
    withLabel?: boolean
    fieldToCheck?: string
    optionKeys?: string[]
    children?: {
      differentBundle: boolean
      static: boolean
      href: string
      key: string
      target: string
      title: string
      visibility?: string[]
    }[]
  }
  itemActions?: {
    toggleTokenIdModal: () => void
    toggleOwnSidebar: () => void
  }
  profileType: string
  legalForm: string
  currenciesStore: any
  userId?: number
  pathname: string
  LinkComponent: React.FC<any>
  handleCloseOpen: (arg1?: string) => void
  activeMenuItem?: string
  hoverOff?: () => void
  setActiveMenuItem: (title: string) => void
  isECActiveAndECVirtualIbanActive?: boolean
  isMangoPayAndMangoVirtualIbanActive?: boolean
}

export const MenuItem: React.FC<MenuItemProps> = observer(
  ({
    item,
    profileType,
    legalForm,
    currenciesStore,
    itemActions,
    pathname,
    LinkComponent,
    handleCloseOpen,
    activeMenuItem,
    hoverOff,
    setActiveMenuItem,
    isECActiveAndECVirtualIbanActive = false,
    isMangoPayAndMangoVirtualIbanActive = false,
    ...props
  }) => {
    const [collapsed, setCollapsed] = useState({})
    const { sellerStore, teamMemberStore } = useSharedStores()

    useEffect(() => {
      setInitRootButtons(item)

      if (isParentActive(item)) {
        setActiveMenuItem(item.title)
      }
    }, [])

    useEffect(() => {
      setCollapsed((prevCollapsed) => ({
        ...prevCollapsed,
        [getCollapsedKey(item)]: isLoaded(item),
      }))
    }, [pathname])

    const getCollapsedKey = (item) => (item.href === '#' ? item.title : item.href)

    const isCurrentItem = activeMenuItem === item.title

    const setInitRootButtons = (item) => {
      setCollapsed((prevCollapsed) => ({
        ...prevCollapsed,
        [getCollapsedKey(item)]: isLoaded(item),
      }))
      if (item.children) {
        item.children.forEach((child) => child.children && setInitRootButtons(child))
      }
    }

    useEffect(() => {
      if (activeMenuItem) {
        if (activeMenuItem === item.title) {
          setCollapsed((prevCollapsed) => ({
            ...prevCollapsed,
            [getCollapsedKey(item)]: activeMenuItem === item.title || isLoaded(item),
          }))
        } else {
          setCollapsed((prevCollapsed) => ({
            ...prevCollapsed,
            [getCollapsedKey(item)]: activeMenuItem === item.title,
          }))
        }
      }
    }, [activeMenuItem])

    const subItemIsVisible = (item) => {
      const { fieldToCheck, href, visibility, optionKeys, profileTypes, useSellerLegalForm } = item
      const field = props[fieldToCheck]
      const isNotContractPage = href !== '/cabinet/contract'
      const checkAvailability = profileType === 'team_member' ? teamMemberStore.hasPermissions : sellerStore.isAppActive
      const currentLegalForm = useSellerLegalForm ? teamMemberStore.getSellerLegalForm : legalForm
      const profileIsCorrect = !profileTypes || (profileTypes || []).some((profile) => profile === profileType)

      switch (profileType) {
        case 'seller':
        case 'team_member':
          return (
            visibility?.includes(currentLegalForm) &&
            (!fieldToCheck || field) &&
            (!optionKeys || checkAvailability(...optionKeys)) &&
            profileIsCorrect
          )
        case 'publisher':
          return isNotContractPage && profileIsCorrect
        case 'elo_publisher':
          return isNotContractPage && profileIsCorrect
        default:
          return (!fieldToCheck || field) && profileIsCorrect
      }
    }

    const getChildren = () => item.children && item.children.filter((el) => subItemIsVisible(el))

    const computedItem = computed(() => ({ ...item, children: getChildren() })).get()

    const itemIsActive = (item) => {
      const { href, subItems = [] } = item
      const isNotRootUrl = !ROOT_URLS.includes(item.href) ? pathname.includes(href) : false

      return (
        href === pathname ||
        href?.split('?')[0] === pathname ||
        isNotRootUrl ||
        subItems.some((route) => pathname.includes(route))
      )
    }

    const isParentActive = (item) => {
      const { children } = item

      if (!children) return false

      const isItemUrlMatched = children.some((child) => pathname.includes(child.href))
      const isSubItemUrlMatched = children.some((child) => child.subItems?.some((c) => pathname.includes(c)))

      return isItemUrlMatched || isSubItemUrlMatched
    }

    const isLoaded = (item) => {
      const { children, href } = item

      let allUrlsList = [item.href]
      if (item.subItems) {
        allUrlsList = [...allUrlsList, ...item.subItems]
      }

      const getAllIncludedUrls = (children, list = allUrlsList) => {
        children.forEach((child) => {
          list = [...list, child.href]

          if (child.subItems) {
            list = [...list, ...child.subItems]
          }

          if (child.children) {
            list = [...list, ...getAllIncludedUrls(child.children, list)]
          }
        })

        return list
      }

      if (children) {
        const allUrlsList = getAllIncludedUrls(children)
        const urlIncluded = allUrlsList.filter((url) => url === pathname || pathname === url?.split('?')[0])
        return !!urlIncluded && !!urlIncluded.length
      }

      const urlIncluded = getAllIncludedUrls([]).filter((url) => url === pathname || pathname.includes(url))

      return !item.children && !ROOT_URLS.includes(item.href)
        ? !!urlIncluded && !!urlIncluded.length
        : pathname === href
    }

    const toggleOpen = (item, value?) => {
      if (item.children) {
        const itemToToggle = getCollapsedKey(item)
        const isOpen = value || !collapsed[itemToToggle]

        setCollapsed((prevCollapsed) => ({
          ...prevCollapsed,
          [itemToToggle]: isOpen,
        }))

        if (!isOpen) {
          item.children.forEach((child) => toggleOpen(child, false))
        }
      }

      setActiveMenuItem(item.title)
    }

    const hasVisibleChildren = () => {
      const { children } = computedItem

      return !children || children.length > 0
    }

    const shouldBeShownByField = () => {
      const { fieldToCheck } = computedItem

      return !fieldToCheck || props[fieldToCheck]
    }

    const isVisible = () => hasVisibleChildren() && shouldBeShownByField()

    const handleArrowSide = (item) => {
      if (item.children) {
        return (
          <div className={`arrow ${collapsed[getCollapsedKey(item)] ? 'opened' : 'closed'}`}>
            <EloCaretDownIcon size={16} />
          </div>
        )
      }
    }

    const iconRender = (id, icon) => {
      const menuItemsIds = {
        products: 'products',
        templates: 'templates',
        embeddableItems: 'embeddableItems',
        team: 'team',
        payout: 'payout',
      }
      const className =
        isLoaded(item) && profileType !== 'payer'
          ? `menu__icon--active ${id && id === menuItemsIds.team ? 'menu__icon--active--team' : ''}`
          : `menu__icon menu__icon--${profileType}`
      switch (id) {
        case menuItemsIds.team:
          return <IconTeam className={className} />
        case menuItemsIds.payout:
          return <IconPayout className={className} />
        default:
          return typeof icon === 'object' ? icon : <i className={icon} />
      }
    }

    const getRootButton = (item) => {
      const {
        icon,
        id,
        href,
        title,
        differentBundle,
        children,
        target = '',
        action,
        ownSidebar,
        badge = '',
        badgeVisibility,
        badgeOptionKeys,
      } = item

      const isInnerRoot = !(icon || id)
      const linkClasses = classNames({
        icon: !isInnerRoot,
        active: isLoaded(item) || isParentActive(item),
        'menu-item__inner-root': !icon && !id,
      })

      const titleClasses = classNames({
        'menu-title': !isInnerRoot,
        'menu-item__inner-title': isInnerRoot,
      })

      const isOpen = !!collapsed[getCollapsedKey(item)]

      const btnContent = (
        <div className='category-container'>
          {!isInnerRoot && iconRender(id, icon)}
          <span className={titleClasses}>
            {title}
            {subItemIsVisible({ ...item, visibility: badgeVisibility, optionKeys: badgeOptionKeys }) &&
              badge &&
              !isOpen && <span className='badge'>{badge}</span>}
          </span>
          {!ownSidebar && handleArrowSide(item)}
        </div>
      )

      const onClick = (e) => {
        e.preventDefault()
        if (typeof itemActions[action] === 'function') {
          itemActions[action]()
          handleCloseOpen()
        }
      }

      switch (true) {
        case differentBundle:
        case ownSidebar:
          return (
            <a aria-expanded href={patchLink(href)} className={linkClasses} target={target}>
              {btnContent}
            </a>
          )
        case !!action:
          return (
            <a aria-expanded href='#' className={linkClasses} onClick={onClick}>
              {btnContent}
            </a>
          )
        default:
          return (
            <LinkComponent
              aria-expanded
              to={children ? pathname : href}
              target={target}
              onClick={() => toggleOpen(item)}
              className={linkClasses}
            >
              {btnContent}
            </LinkComponent>
          )
      }
    }

    const getMenuItemId = (value) => camelcase(`sideMenu ${value.replace(/\W+/g, ' ')}`)

    const getSubItem = ({ collapsed, item }) => (
      <ul className={collapsed ? 'opened' : 'closed'}>
        {item.children.map((item, key) => {
          const { action, href, title, differentBundle, target = '', badge = '' } = item

          if (item?.isMongoPayReady && !isMangoPayAndMangoVirtualIbanActive) {
            return null
          }

          if (item?.isElopageConnectReady && !isECActiveAndECVirtualIbanActive) {
            return null
          }

          if (item.children) {
            return getRegularItem(item)
          }

          return (
            subItemIsVisible(item) && (
              <li id={getMenuItemId(`${item.title} SubItem`)} key={key}>
                <DynamicLink
                  differentBundle={differentBundle}
                  className={itemIsActive(item) ? 'active' : ''}
                  to={href}
                  onClick={action ? action : null}
                  target={target}
                  LinkComponent={LinkComponent}
                >
                  <div className='subcategory-container'>
                    <span className='title'>{title}</span>
                    {badge && <span className='badge'>{badge}</span>}
                  </div>
                </DynamicLink>
              </li>
            )
          )
        })}
      </ul>
    )

    const getRegularItem = (item) => {
      const { children, ownSidebar } = item

      return isVisible() ? (
        <>
          {getRootButton(item)}
          {children &&
            children.length &&
            getSubItem({
              collapsed: ownSidebar || collapsed[getCollapsedKey(item)],
              item,
            })}
        </>
      ) : null
    }

    const getMobileAppIconVisibility = () => {
      const { optionKeys = [] } = teamMemberStore.selectedSellerInvite || {}
      return optionKeys.includes('mobile_app')
    }

    const getCountingItem = () => {
      const { permissionsKeys = [], state } = teamMemberStore.selectedSellerInvite || {}

      const isTeamMemberProfile = profile.profileType === ACTIVE_PROFILES.teamMember

      const isMobileAppBooked = sellerStore.isAppActive(MOBILE_APP_OPTION_KEY)
      const isCommunityAppBooked = sellerStore.isAppActive(COMMUNITY_KEY)
      const isMobileAppBookedForPayer = getMobileAppIconVisibility()

      const isMobileAppActive = isTeamMemberProfile
        ? isMobileAppBookedForPayer && permissionsKeys.includes(MOBILE_APP_OPTION_KEY) && state === STATUSES.approved
        : isMobileAppBooked

      const mobileItemClasses = classNames('menu-item--mobile-app', {
        active: isLoaded(item),
      })

      switch (computedItem.type) {
        case MOBILE_APP_OPTION_KEY:
          return (
            isMobileAppActive && (
              <>
                <LinkComponent to={item.href} className={mobileItemClasses} onClick={() => toggleOpen(item)}>
                  <div className='category-container'>
                    <div>{item?.icon}</div>
                    <div className='menu-title'>{item.title}</div>
                    {handleArrowSide(item)}
                  </div>
                </LinkComponent>
                {item.children &&
                  getSubItem({
                    collapsed: collapsed[getCollapsedKey(item)],
                    item,
                  })}
              </>
            )
          )

        case UNACTIVE_PROFILES.reseller:
          productsStore.fetchInReviewCount()
          resellerRequestsStore.fetchCount()

          return (
            <CountingItem
              {...{
                item,
                isLoaded,
                count: [productsStore.inReviewCount, resellerRequestsStore.count],
                onClick: () => toggleOpen(item),
                data: {
                  currenciesStore: currenciesStore,
                },
              }}
            >
              {item.children &&
                getSubItem({
                  collapsed: isCurrentItem,
                  item,
                })}
            </CountingItem>
          )
        case COMMUNITY_KEY: {
          return isCommunityAppBooked && getRegularItem(item)
        }
        default:
          return getRegularItem(item)
      }
    }

    const menuItem = computedItem.withLabel ? getCountingItem() : getRegularItem(item)

    const liClasses = classNames({
      active: isLoaded(item) || isParentActive(item),
      'active--white': isLoaded(item) && profileType === ACTIVE_PROFILES.payer,
      'active--grey': isLoaded(item) && profileType === ACTIVE_PROFILES.manager,
    })

    return menuItem ? (
      <li id={getMenuItemId(item.title)} className={liClasses}>
        {menuItem}
      </li>
    ) : null
  }
)
